.navLinkWrapper:before, .navLinkWrapper:after {
    width: 100%;
    height: 1px;
    background-color: black;
    content: '';
    display: flex;
    flex-direction: row;
    opacity: 0;
    transition: all 0.3s;
}

.navLinkWrapper:before
{
    top: 0px;
    transform: translateY(10px);
}

.navLinkWrapper:after
{
    bottom: 0px;
    transform: translateY(-10px);
}

.navLinkWrapper:hover:before,  .navLinkWrapper:hover:after{
    opacity: 1;
    transform: translateY(0px);
}

.navLink {
    text-decoration: none;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    color: white;
}