body, div, p {
    margin: 0;
    padding: 0;
}

body {
    background-color: #F8F8F8;
}

p {
    text-decoration: none;
    color: black;
}

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 200;
    src: url('../assets/fonts/AvenirLight.otf');
}
