/* PLAYFAIR */
.playfairTitle {
    font-size: 75px;
}
.playfairLarge {
    font-size: 50px;
}
.playfairMedium {
    font-size: 30px;
}
.playfairSmall {
    font-size: 22px;
}
.playfairTiny {
    font-size: 18px;
}
.playfairTitle, .playfairLarge, .playfairMedium, .playfairSmall, .playfairTiny {
    color: black;
    font-family: 'Playfair Display';
    font-weight: 700;
    z-index: 1;
}
/*************/

/* ROBOTO */
.robotoTitle {
    font-size: 60px;
    font-weight: 700;
}
.robotoLarge {
    font-size: 40px;
}
.robotoMedium {
    font-size: 20px;
}
.robotoSmall {
    font-size: 16px;
}
.robotoTitle, .robotoLarge, .robotoMedium, .robotoSmall {
    color: black;
    font-family: 'Roboto';
    font-weight: 700;
}
/*************/

/* RALEWAY */
.ralewayTitle {
    font-size: 60px;
    font-weight: 700;
}
.ralewayLarge {
    font-size: 40px;
    font-weight: 500;
}
.ralewayMedium {
    font-size: 22px;
    font-weight: 400;
}
.ralewaySmall {
    font-size: 18px;
    font-weight: 400;
}
.ralewayTitle, .ralewayLarge, .ralewayMedium, .ralewaySmall {
    font-family: 'Raleway';
    z-index: 1;
}
/*************/

/* MEDIA */
@media (max-width: 1000px) {
    .playfairTitle {
        font-size: 60px;
    }
    .playfairMedium {
        font-size: 25px;
    }
}
@media (max-width: 800px) {
    .playfairTitle {
        font-size: 40px;
    }
    .playfairMedium {
        font-size: 20px;
    }
    .playfairSmall {
        font-size: 18px;
    }
    .playfairTiny {
        font-size: 16px;
    }
    .ralewayTitle {
        font-size: 50px;
    }
    .ralewayLarge {
        font-size: 35px;
    }
    .ralewayMedium {
        font-size: 20px;
    }
    .ralewaySmall {
        font-size: 18px;
    }
}
@media (max-width: 600px) {
    .robotoTitle {
        font-size: 40px;
    }
    .robotoLarge {
        font-size: 30px;
    }
    .ralewayTitle {
        font-size: 40px;
    }
    .ralewayLarge {
        font-size: 30px;
    }
}
@media (max-height: 675px) {
    .robotoTitle {
        font-size: 30px;
    }
    .robotoLarge {
        font-size: 20px;
    }
    .ralewayTitle {
        font-size: 30px;
    }
    .ralewayLarge {
        font-size: 20px;
    }
    .ralewayMedium {
        font-size: 18px;
    }
    .ralewaySmall {
        font-size: 16px;
    }
}
/*************/
