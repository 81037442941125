body, div, p {
    margin: 0;
    padding: 0;
}

body {
    background-color: #F8F8F8;
}

p {
    text-decoration: none;
    color: black;
}

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 200;
    src: url(/static/media/AvenirLight.0b78ec9d.otf);
}

/* PLAYFAIR */
.TextStyles_playfairTitle__2MDee {
    font-size: 75px;
}
.TextStyles_playfairLarge__ISwYz {
    font-size: 50px;
}
.TextStyles_playfairMedium__28Np1 {
    font-size: 30px;
}
.TextStyles_playfairSmall__25tLD {
    font-size: 22px;
}
.TextStyles_playfairTiny__2RR33 {
    font-size: 18px;
}
.TextStyles_playfairTitle__2MDee, .TextStyles_playfairLarge__ISwYz, .TextStyles_playfairMedium__28Np1, .TextStyles_playfairSmall__25tLD, .TextStyles_playfairTiny__2RR33 {
    color: black;
    font-family: 'Playfair Display';
    font-weight: 700;
    z-index: 1;
}
/*************/

/* ROBOTO */
.TextStyles_robotoTitle__3Yced {
    font-size: 60px;
    font-weight: 700;
}
.TextStyles_robotoLarge__pDsYZ {
    font-size: 40px;
}
.TextStyles_robotoMedium__r8ZVD {
    font-size: 20px;
}
.TextStyles_robotoSmall__3OpN- {
    font-size: 16px;
}
.TextStyles_robotoTitle__3Yced, .TextStyles_robotoLarge__pDsYZ, .TextStyles_robotoMedium__r8ZVD, .TextStyles_robotoSmall__3OpN- {
    color: black;
    font-family: 'Roboto';
    font-weight: 700;
}
/*************/

/* RALEWAY */
.TextStyles_ralewayTitle__1HcKq {
    font-size: 60px;
    font-weight: 700;
}
.TextStyles_ralewayLarge__2oWlg {
    font-size: 40px;
    font-weight: 500;
}
.TextStyles_ralewayMedium__7s8s- {
    font-size: 22px;
    font-weight: 400;
}
.TextStyles_ralewaySmall__27WPx {
    font-size: 18px;
    font-weight: 400;
}
.TextStyles_ralewayTitle__1HcKq, .TextStyles_ralewayLarge__2oWlg, .TextStyles_ralewayMedium__7s8s-, .TextStyles_ralewaySmall__27WPx {
    font-family: 'Raleway';
    z-index: 1;
}
/*************/

/* MEDIA */
@media (max-width: 1000px) {
    .TextStyles_playfairTitle__2MDee {
        font-size: 60px;
    }
    .TextStyles_playfairMedium__28Np1 {
        font-size: 25px;
    }
}
@media (max-width: 800px) {
    .TextStyles_playfairTitle__2MDee {
        font-size: 40px;
    }
    .TextStyles_playfairMedium__28Np1 {
        font-size: 20px;
    }
    .TextStyles_playfairSmall__25tLD {
        font-size: 18px;
    }
    .TextStyles_playfairTiny__2RR33 {
        font-size: 16px;
    }
    .TextStyles_ralewayTitle__1HcKq {
        font-size: 50px;
    }
    .TextStyles_ralewayLarge__2oWlg {
        font-size: 35px;
    }
    .TextStyles_ralewayMedium__7s8s- {
        font-size: 20px;
    }
    .TextStyles_ralewaySmall__27WPx {
        font-size: 18px;
    }
}
@media (max-width: 600px) {
    .TextStyles_robotoTitle__3Yced {
        font-size: 40px;
    }
    .TextStyles_robotoLarge__pDsYZ {
        font-size: 30px;
    }
    .TextStyles_ralewayTitle__1HcKq {
        font-size: 40px;
    }
    .TextStyles_ralewayLarge__2oWlg {
        font-size: 30px;
    }
}
@media (max-height: 675px) {
    .TextStyles_robotoTitle__3Yced {
        font-size: 30px;
    }
    .TextStyles_robotoLarge__pDsYZ {
        font-size: 20px;
    }
    .TextStyles_ralewayTitle__1HcKq {
        font-size: 30px;
    }
    .TextStyles_ralewayLarge__2oWlg {
        font-size: 20px;
    }
    .TextStyles_ralewayMedium__7s8s- {
        font-size: 18px;
    }
    .TextStyles_ralewaySmall__27WPx {
        font-size: 16px;
    }
}
/*************/

.NavBarLink_navLinkWrapper__I-xLK:before, .NavBarLink_navLinkWrapper__I-xLK:after {
    width: 100%;
    height: 1px;
    background-color: black;
    content: '';
    display: flex;
    flex-direction: row;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.NavBarLink_navLinkWrapper__I-xLK:before
{
    top: 0px;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
}

.NavBarLink_navLinkWrapper__I-xLK:after
{
    bottom: 0px;
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}

.NavBarLink_navLinkWrapper__I-xLK:hover:before,  .NavBarLink_navLinkWrapper__I-xLK:hover:after{
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
}

.NavBarLink_navLink__1gGKC {
    text-decoration: none;
    letter-spacing: 2px;
    cursor: pointer;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    color: white;
}
.Animations_slideUpImage__BFD2V {
    position: absolute;

    -webkit-animation: Animations_slideUp__22FBZ 2s ease-out;

            animation: Animations_slideUp__22FBZ 2s ease-out;
}

@-webkit-keyframes Animations_slideUp__22FBZ {
    0% {
        top: 100vh;
    }
    100% {
        top: 0;
    }
}

@keyframes Animations_slideUp__22FBZ {
    0% {
        top: 100vh;
    }
    100% {
        top: 0;
    }
}

.Animations_slideUpSun__1ZCPh {
    -webkit-animation: Animations_slideUp2__3l6QI 2s ease-out;
            animation: Animations_slideUp2__3l6QI 2s ease-out;
}

@-webkit-keyframes Animations_slideUp2__3l6QI {
    0% {
        top: 100vh;
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        top: 150px;
    }
}

@keyframes Animations_slideUp2__3l6QI {
    0% {
        top: 100vh;
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        top: 150px;
    }
}

.Animations_slideInPlane__3woIZ {
    position: absolute;
    top: 0;
    left: 25px;

    -webkit-animation: Animations_slideIn__30tm6 1.5s ease-out;

            animation: Animations_slideIn__30tm6 1.5s ease-out;
}

@-webkit-keyframes Animations_slideIn__30tm6 {
    0% {
        left: -700px;
        top: 700px;
    }
    100% {
        left: 25px;
    }
}

@keyframes Animations_slideIn__30tm6 {
    0% {
        left: -700px;
        top: 700px;
    }
    100% {
        left: 25px;
    }
}

.Projects_mock__-NaHh {
    bottom: 10vw;
    position: absolute;
    width: 30%;
}

.Projects_whiteBackground__37YoT {
    bottom: 0;
    background-color: white;
    height: 20vw;
    opacity: 0.7;
    width: 650px;
    zIndex: 0;
}

@media (max-width: 800px) {
    .Projects_mock__-NaHh {
        bottom: 20vw;
        width: 45%;
    }
    .Projects_whiteBackground__37YoT {
        bottom: -58px;
        height: 30vw;
        width: 450px;
    }
}

@media (max-width: 600px) {
    .Projects_mock__-NaHh {
        bottom: 30vw;
    }
    .Projects_whiteBackground__37YoT {
        bottom: -100px;
        height: 40vw;
        width: 300px;
    }
}
.Spades_iconsWrapper__Hf2YP {
    width: 40%;
}

@media (max-width: 925px) {
    .Spades_iconsWrapper__Hf2YP {
        width: 100%;
    }
    .Spades_rightSide__cATnj {
        display: none;
        overflow: hidden;
    }
}
