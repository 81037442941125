.slideUpImage {
    position: absolute;

    animation: slideUp 2s ease-out;
}

@keyframes slideUp {
    0% {
        top: 100vh;
    }
    100% {
        top: 0;
    }
}

.slideUpSun {
    animation: slideUp2 2s ease-out;
}

@keyframes slideUp2 {
    0% {
        top: 100vh;
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        top: 150px;
    }
}

.slideInPlane {
    position: absolute;
    top: 0;
    left: 25px;

    animation: slideIn 1.5s ease-out;
}

@keyframes slideIn {
    0% {
        left: -700px;
        top: 700px;
    }
    100% {
        left: 25px;
    }
}
