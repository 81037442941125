.mock {
    bottom: 10vw;
    position: absolute;
    width: 30%;
}

.whiteBackground {
    bottom: 0;
    background-color: white;
    height: 20vw;
    opacity: 0.7;
    width: 650px;
    zIndex: 0;
}

@media (max-width: 800px) {
    .mock {
        bottom: 20vw;
        width: 45%;
    }
    .whiteBackground {
        bottom: -58px;
        height: 30vw;
        width: 450px;
    }
}

@media (max-width: 600px) {
    .mock {
        bottom: 30vw;
    }
    .whiteBackground {
        bottom: -100px;
        height: 40vw;
        width: 300px;
    }
}